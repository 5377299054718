@media (max-width: 700px) {
  .centersideItem input {
    width: 85%;
  }
  #navbarCollapse {
    padding: 10px 10px;
  }
  .elementor-icon-list-items-header {
    gap: 10px;
    padding-left: 5px;
  }
  .mainLogoImage {
    max-width: 60px;
    max-height: 69px;
    margin-top: 0px;
  }
  .signInButton {
    padding: 5px 10px;
  }
  .header-login-btns {
    gap: 20px;
  }
  .welcome-text-sec-top-con {
    display: flex;
    gap: 30px;
  }
  .job-card-dashboard-alerts {
    display: none;
  }
  .welcome-text-sec-top-left,
  .welcome-text-sec-top-right {
    font-size: 14px;
  }
  .layout-container {
    display: block !important;
  }
  .edit-p-text {
    line-height: 22px;
  }
  .profileEdit {
    font-size: 12px;
  }
  .student-fields-sec-content-all {
    grid-template-columns: 1fr !important;
  }
}
