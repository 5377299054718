.headerName {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 39.0625px;
  line-height: 63px;
  color: #000000;
  float: left;
  margin-bottom: 56px;
}

.Usertable-info {
  /* margin: 56px 70px 70px; */
}
.showUnderLineText {
  text-decoration: underline;
}
.infoList {
  width: 100%;
}
.historyList {
  margin-bottom: 500px;
}
.card-columns1 {
  width: 100%;
  min-height: 100px;
  display: inline-block;
}

.card-columns1 > div {
  width: 33.3%;
  float: left;
  min-height: 100px;
  padding: 5px;
  overflow-wrap: break-word;
}

.InvoiceDownload {
  width: 219px;
  height: 38px;
  border: 1px solid #000000;
  background-color: #ffffff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}

.InvoiceDownload:hover,
.InvoiceDownload:focus,
.InvoiceDownload :active {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #333333 !important;
  box-shadow: none !important;
}

.umHeadRow {
  justify-content: space-between;
}

.userSearchRow {
  justify-content: flex-end;
  margin-bottom: 32px !important;
  margin-top: 30px;
}

.showUnderLine span {
  text-decoration: underline;
  cursor: pointer;
}

.times {
  position: absolute;
  font-size: 40px;
  top: -35px;
  right: -70px;
  cursor: pointer;
}

.filterByBlock {
  margin-left: 24px;
  width: 300px;
  height: 45px;
  left: 1221px;
  top: 243px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.filterByBlock .sortByText {
  width: 100px;
}
.filterBySelect {
  border: none;
  width: 90px !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #333333;
  padding-left: 2px;
  padding-right: 2px;
}

.filterBySelect:focus,
.filterBySelect:hover,
.filterBySelect:active {
  border: none !important;
  box-shadow: unset !important;
}

.Usertable-info thead > .headerNamesList {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.Usertable-info thead tr {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 19%);
  height: 91px;
}

.Usertable-info table {
  background: #f9f9f9;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.Usertable-info tbody tr {
  height: 91px;
  /* border-top: 1px solid #333333; */
  border-top: 1px solid #e6e3e3;
}

.Usertable-info td,
.Usertable-info th {
  padding: 0px 8px;
}

.backheaderName {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  padding: 30px 59px 63px;
  display: flex;
  align-items: center;
}

.umChevronLeft {
  cursor: pointer;
  margin-right: 10px;
}

.backheaderName1 {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.avatarImage {
  width: 45px;
  height: 45px;
  background: #c4c4c4;
  border-radius: 25px;
  text-transform: capitalize;
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #919191;
  padding-top: 6px;
  text-align: center;
}

.nameCapitalize {
  text-transform: capitalize;
}

.centerText {
  width: 820px;
  /* margin-left: 15%; */
  margin: auto;
}

.userImage {
  height: 50px;
  width: 50px;
}

.avatorIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.basicUserInfo {
  background: #fbfbfb;
  padding: 25px;
  border-radius: 4px;
}

.basicUserInfo .infoList {
  margin-top: 30px;
}

.PurchaseHistory {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 20px;
}

.History-info {
  margin-left: 100px;
}

.History-info thead tr {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 19%);
  height: 91px;
}

.History-info table {
  background: #f9f9f9;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.History-info tbody tr {
  height: 91px;
  border-top: 1px solid #333333;
}

.History-info td,
.History-info th {
  padding: 0px 15px;
}

.RowLevelClass td {
  cursor: pointer;
}

.bodyRowTextStyles {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #333333;
}

.blockIcon {
  text-align: center;
  cursor: pointer;
}
.Usertable-info .actionsDropdown button {
  background: #fff !important;
  border: 1px solid #000 !important;
}
