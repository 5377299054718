.bodySection {
  margin: auto;
  color: #333;
  /* background: #f5f5f5; */
}
body {
  font-family: inherit;
  font-size: 16px;
  color: #333;
  /* background-color: #f5f5f5 !important; */
}
iframe {
  display: none;
}
.adminControlApp {
  min-height: 1000px;
  /* display: inline-block; */
  background: #f5f5f5;
}
.drop-down-single-item-sec {
  font-size: 16px;
  margin: 2px 10px;
  display: block;
  color: #14a2b8;
}
.job-card-details-popup > .modal-dialog {
  margin-top: 100px;
}
.job-card-details-popup .focusnext-boby-section {
  max-height: 500px;
  overflow: scroll;
}
.pac-container {
  z-index: 199999000;
}
.d-flex {
  display: flex;
}
.d-flex-s-b {
  justify-content: space-between;
}

.conformation-add-user-model-box1 .modal-dialog {
  max-width: 800px;
}
.conformation-change-password-box1 .modal-dialog {
  max-width: 550px;
}
.image-upload-model-box .modal-dialog {
  max-width: 600px;
}
