#navbarCollapse {
  display: flex !important;
  align-items: center;
  width: 100%;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
  box-shadow: 0px 4px 16px 0px #00000029;
  background-color: #fff;
  height: 70px;
  padding: 10px 50px;
  color: #222222;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 0px 0px;
  background-color: rgb(255, 255, 255);

  /* background-color: #e2e2e2; */
}
.edit-sec-prev-values .deltape-button {
  /* padding-top: 4px; */
}
.action-icons-sec-new {
  display: flex;
  justify-content: space-around;
  gap: 7px;
}
.action-icons-sec-new img {
  height: 35px;
  width: 35px;
  cursor: pointer;
  border-radius: 12px;
}
.payment-mode-model-box,
.create-student-model-box {
  margin-top: 50px;
}
.logout-text-sec {
  color: #1565c0;
}
#headerWrapper {
  height: 60px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 99999;
}

.color-fff {
  color: #fff;
}
.header-popover-box {
  z-index: 50;
}

.welcome-text-sec-top {
  padding-top: 70px;
  background: #fff;
}

.welcome-text-sec-top-con {
  background: #f0f3f6;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  color: #214287;
}

.logo-text-main {
  cursor: pointer;
  font-size: 40px;
  text-transform: capitalize;
  line-height: 50px;
  display: block;
}

.nav-bar-sec-mobile-sec {
  width: 80%;
  z-index: 999999;
}
.nav-bar-sec-mobile-sec .offcanvas-header {
  padding: 12px;
}
.nav-bar-sec-mobile-sec .btn-close {
  margin-top: -25px;
}
.nav-bar-sec-mobile-sec .offcanvas-body {
  font-size: 16px;
  padding: 12px 15px;
  border-radius: 8px;
  background: rgb(21, 101, 192);
  color: #fff;
}
.nav-bar-sec-mobile-sec .main-menu-sec {
  margin-top: 12px;
}
.nav-bar-sec-mobile-sec .mobile-links-menu-sec-all {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #e2e2e2;
}
.nav-bar-sec-mobile-sec .mobile-links-menu-sec-all > div {
  line-height: 40px;
}
.nav-bar-sec-mobile-sec .mobile-links-menu-sec-all > div.active {
  background: rgb(174, 231, 241);
  border-radius: 8px;
  color: rgb(21, 101, 192) !important;
  padding-left: 16px;
}
.nav-bar-sec-mobile-sec .logout-text-sec {
  color: #fff;
}
.nav-bar-sec-mobile-sec .logout-text-sec img {
  background: #fff;
}
.deltape-header-sec-nav-bar,
.deltape-header-sec-nav-bar .container-fluid {
  padding: 0px !important;
}
.left-header-sec-cont-name {
  display: flex;
  gap: 12px;
}
.leftsideItem img {
  cursor: pointer;
}
.ant-tabs-nav-list * {
  font-size: 18px;
}
.color-picker-close-icon {
  display: flex;
  justify-content: end;
}
.mainLogoImage {
  max-width: 140px;
  max-height: 65px;
  margin-top: -7px;
}

#navbarCollapse ul {
  flex-direction: row;
  /* margin-right: auto !important;
    margin-left: auto !important;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; */
}

.leftsideItem img {
  cursor: pointer;
}

.signed-user-name {
  margin-top: 7px;
  margin-right: 5px;
  text-transform: capitalize;
}

.signInButtonArea {
  margin-left: 25px;
  display: flex;
}

.signInButton {
  background-color: #ff5733;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 35px;
  border-radius: 4px;
  height: 45px;
}

#navbarCollapse ul li {
  display: inline;
  /* padding: 0px 20px; */
}

.centersideItem input {
  width: 300px;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 10px;
}

.cart_items_count {
  position: absolute;
  margin-top: -5px;
  color: #222020;
}

.rightsideItem > span {
  cursor: pointer;
}

/* #navbarCollapse ul li a {
  text-decoration: auto;
  color: #000;
} */

.headerSection {
  background-color: #ffffff;
}

.allItemsSection {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.job-card-dashboard-alerts {
  background: #35ffc3;
  width: 55%;
  text-align: center;
  padding-bottom: 1px;
  /* // padding-top: 5px; */
  /* margin-bottom: 5px; */
  border-radius: 4px;
  display: flex;
  /* padding: 5px; */
  gap: 30px;

  /* h3 {
    color: #ed1e24;
    text-decoration: underline;
    margin-bottom: 7px;
    font-size: 20px;
    width: 10%;
  }
  p {
    color: #204289;
  } */
}

.job-card-dashboard-alerts h3 {
  color: #ed1e24;
  text-decoration: underline;
  margin-bottom: 0px;
  font-size: 20px;
  width: 10%;
}

.job-card-dashboard-alerts p {
  color: #204289;
}

.alerts-text-row {
  width: 90%;
}

.alerts-text-row p {
  margin-left: 100px;
  cursor: pointer;
  margin-bottom: 5px;
}

.allItemsSection > div {
  text-align: left;
  /* height: 70px; */
}

.cartIconPage {
  margin-top: 4px;
}

/* .leftsideItem {
  width: 7%;
}

.rightsideItem {
  width: 35%;
}
.centersideItem {
  width: 58%;
} */
.rightsideItem {
  display: flex;
  justify-content: right;
  /* padding-top: 5px; */
}

.profileIconStyles {
  width: 38px;
  height: 38px;
  /* background-color: #ccc; */
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.profileIconStyles svg {
  height: 33px;
  width: 33px;
}

.header-popover-box svg.Popover-tip {
  margin-top: 8px;
  fill: #ff5733;
}

.elementor-icon-list-items-header {
  padding-top: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 25px;
}

.elementor-icon-list-items-header a {
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
}

.elementor-icon-list-items-header a:hover {
  color: #ff5733;
  background: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  text-decoration: none;
}

.elementor-icon-list-items-header::before {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.menuDropdownMainSection {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  width: 200px;
  background-color: #fff;
  padding: 10px 20px;

  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.18);
  right: 23px;
  padding: 5px;
  border-radius: 3px;
}

.menuDropdownMainSection .items-list {
  cursor: pointer;
  margin: 10px 0px;
  padding: 5px 0px;
  padding-left: 10px;
}

.Popover-body .menuDropdownMainSection > div:not(.items-list-signout) {
  border-bottom: 1px solid #e2e2e2;
}

.filter-btn-btn {
  text-transform: capitalize;
  background: steelblue;
  line-height: 35px;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
  color: #fff;
  border: none;
  padding: 0px 40px;
  height: 40px;
  font-size: 20px;
  margin-top: -2px;
}

.header-login-btns {
  display: flex;
  gap: 30px;
}

.header-login-btns .sign-up {
  background: #fff;
  color: #ff5733;
  font-size: 17px;
  font-weight: 600;
}
.forgot-pass-model-sec .modal-dialog {
  max-width: 600px;
}

/* .Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}
.Select-menu {
  position: fixed !important;
}
.Select.is-open {
  z-index: 1000 !important;
}
.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
} */
.main-note-sec {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #343434;
  font-size: 14px;
}
.main-note-s .note-sec {
  font-weight: 700;
}

.delta-select > div {
  margin-top: 0px;
  border-radius: 8px;
}

.delta-select-row {
  margin-top: 16px;
}
.delta-select-row label {
  font-size: 18px;
  margin-bottom: 2px;
}
.deltape-button {
  background: rgb(23, 162, 187);
  color: rgb(255, 255, 255);
  padding: 10px 30px;
  border: none;
  border-radius: 8px;
  height: 42px;
  font-size: 18px;
  font-size: 14px;
  padding: 12px 15px;
  border-radius: 8px;
  background: #1565c0;
  color: #fff;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  text-align: center;
}
.invalid-feedback {
  display: block !important;
  margin-top: 0px !important;
}
.deltape-table-view {
  margin-top: 10px;
  margin-bottom: 50px;
}
.deltape-table-view tr th {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.deltape-table-view tr td {
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 10px 5px !important;
}
.delata-table-pagination {
  margin-top: 10px;
  text-align: center;
}
.deltape-cancel-button {
  background: #fff;
  padding: 10px 30px;
  border: 1px solid rgb(23, 162, 187);
  color: rgb(23, 162, 187);
  height: 50px;
  font-size: 20px;
  margin-right: 30px;
  border-radius: 8px;
}
.menu-items-sec-all {
  font-size: 46px;
  line-height: 20px;
  margin-left: 13px;
}

.payment-mode-model-box .modal-dialog {
  max-width: 650px;
}
.create-student-model-box .modal-dialog {
  max-width: 780px;
}
.add-user-model-popup .modal-dialog {
  max-width: 600px;
}
.create-student-model-box .modal-content {
  border-radius: 16px;
}
.delta-select-column-error .delta-select__control {
  border-color: red;
}
.btn-primary:hover {
  background: #1565c0 !important;
  color: #fff !important;
}
.btn-primary {
  border-color: #1565c0 !important;
}
.error_message {
  color: red;
}
.deltape-form {
  label {
    font-size: 16px;
    margin-bottom: 2px;
    color: #7f8b93;
  }
  input {
    height: 40px;
    border-radius: 8px;
  }
  h4 {
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 10px;
  }
}
.delta-select__indicator-separator {
  width: 0px !important;
}
.delta-select__value-container--is-multi {
  height: auto;
}
.delta-select__input-container,
.delta-select,
.delta-select__control,
.delta-select__option,
.delta-select__value-container {
  height: 45px;
}
.delta-select__input-container {
  margin: 0px !important;
}
.delta-select__value-container {
  padding-top: 0px !important;
}
.ant-table-thead tr th {
  padding-left: 5px !important;
}
.total-count-row {
  font-size: 14px;
  color: #1565c0;
}
/* .deltape-table-view .ant-table-measure-row {
  display: grid !important;
} */
.delata-table-pagination .ant-pagination-item-active {
  background: rgb(23, 162, 184);
  color: rgb(255, 255, 255);
  border-color: rgb(23, 162, 184);
}
.add_new_child_step1 .modal-dialog {
  max-width: 650px;
}
.add_new_child_step2 .modal-dialog {
  max-width: 1150px;
}
.add_new_child_step .modal-body {
  padding: 24px;
}
.deltape-action-btns {
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-top: 20px;
}
.deltape-action-btns .cancel-btn {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #000;
  border: 1px solid #1565c0;
  background-color: #fff;
  width: 50%;
  border-radius: 8px;
  height: 40px;
}
.deltape-action-btns .confirm-btn {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #1565c0;
  background-color: #1565c0;
  width: 50%;
  border-radius: 8px;
  height: 40px;
}
.my-chart-container {
  width: 100%;
}
.checkboxtacbox {
  display: flex;
  /* display: table; */
  gap: 12px;
}
.checkboxtacbox #checkboxaggrews {
  /* top: -11px; */
  position: relative;
  width: 18px;
}
.checkboxtacbox label {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19.36px !important;
  /* text-align: left !important; */
  color: #000 !important;
  cursor: pointer;
}
.column2 {
  display: flex;
  gap: 16px;
  padding: 0;
}
