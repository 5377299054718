.login {
  padding: 50px;
  border: 1px solid #ccc;
  background: #f5f5f5;
  margin-left: 50%;
  margin-top: 10%;
}
.section-container-body .table > :not(caption) > * > * {
  box-shadow: none;
}
.section-container-body .table thead th {
  border-bottom: 0px;
  vertical-align: top;
}
.print-page {
  background-color: #f0f3f6;
  min-height: 800px;
}
.login h5 {
  /* background: none !important;
  border-bottom: 2px solid #777;
  margin-top: 3%;
  margin-bottom: 5%;
  display: block; */
  margin-top: 3%;
  color: #4d4d4d;
  text-align: center;
}
.left-menu-container {
  background: #efefef;
}
.form-control {
  display: block;
  width: 100% !important;
}
.login .btn-primary {
  width: 100%;
}
.FCLogo {
  text-align: center;
}
.FCLogo img {
  width: 65%;
}
.p-0 {
  padding: 0px !important;
}
.p-3 {
  padding: 30px !important;
}
.p-2 {
  padding: 20px !important;
}
.m-0 {
  margin: 0px !important;
}

.mt-15 {
  margin-top: 15px !important;
}
.Title {
  background: red;
  color: #fff;
}
.Title h3 {
  padding: 5px 15px;
}
.ProductDetails {
  background: #fff;
  margin-top: 15px;
}
.ProductDetails h5 {
  padding: 2%;
  background: #ccc;
}
.ProductDetails .col-form-label {
  position: absolute;
  top: -10px;
  background: #fff;
  padding: 0px 15px;
}
.ProductDetails .form-control {
  width: 66%;
  margin: 2%;
  text-transform: capitalize;
}
.ItemsRecieved {
  background: #fff;
  margin-top: 15px;
}
.ItemsRecieved h4 {
  background: #91ce80;
  display: block;
  width: 100%;
  padding: 5px 15px;
  color: #f9f9f9;
}
.form-check {
  margin: 5px 15px;
}
select {
  width: 100%;
  height: 35px;
  border: 1px solid #ced4da;
}
select option {
  padding: 10px !important;
  line-height: 25px;
  height: 30px;
}
.TermsConditions {
  background: #fff;
  margin-top: 15px;
}
.Logos {
  margin-top: 15px;
}
.Logos img {
  max-width: 100%;
}

/* Table Css Start 
  ========================== */
table {
  width: 100% !important;
  /* margin-bottom: 1rem; */
  color: #212529;
}
.ant-table-header table {
  border-left: 1px solid #e0e7ed;
  border-right: 1px solid #e0e7ed;
  border-top: 1px solid #e0e7ed;
  /* border: 1px solid #E0E7ED; */
}
.ant-table-body table {
  border: 1px solid #e0e7ed;

  /* border: 1px solid #E0E7ED; */
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e0e7ed;
  font-size: 16px !important;
}
.datatable {
  overflow: scroll;
  height: calc(100vh - 120px);
}
.DashboardDatatable {
  overflow: scroll;
  height: calc(100vh - 300px);
}
.datatable tr:nth-child(odd) {
  background: #999797;
}

/* Side Menu 
  ========================== */
.sidemenu {
  background: #e2e2e2;
  color: #000;
  padding: 0px;
  height: 100vh;
  overflow: scroll;
}
/* .sidemenu span {
    padding: 15px;
    color: #000;
    text-align: center;
    display: block;
    background: #242424;
  } */
.left-side-menu-content {
  margin-top: 10px;
}
.sidemenu ul {
  margin: 0px;
  padding: 0px;
}
.sidemenu ul.down-section-content li {
  list-style: none;
  /* padding: 18px 10px; */
  color: #000;
  border-top: 1px solid #fff;
  /* border-bottom: 1px solid #fff; */
}
.sidemenu ul.top-section-content li {
  list-style: none;
  /* padding: 18px 10px; */
  color: #000;
  /* border-bottom: 1px solid #fff; */
  /* border-bottom: 1px solid #fff; */
}
.sidemenu ul li a {
  color: #000;
  width: 100%;
  display: block;
}
.sidemenu .fa-angle-right:before,
.sidemenu .fa-angle-down:before {
  color: #999696;
}

.sidemenu h2 {
  width: 100%;
  /* margin-top: 10px; */
  margin: 0px;
  padding: 15px 0px 15px 0;
}
.sidemenu h2.itemrow2 {
  border-top: 1px solid #fff;
}

.sidemenu h2 .accordion-button {
  font-size: 17px;
  /* background: steelBlue !important; */
  /* color: #eee; */
  /* background: #f9f9f9; */
  background: #e2e2e2;
  border: none;
  padding: 5px 10px;
  width: 100%;
  text-align: left;
  font-weight: 400;
  cursor: pointer;
}
.sidemenu .accordion-body {
  /* background: #fff; */
  margin: 5px;
}
.sidemenu .accordion-body li {
  list-style: none;
}
.sidemenu .accordion-body li a {
  width: 100%;
  text-align: left;
  font-size: 13px;
  /* color: #4d4d4d; */
  display: block;
  font-size: 17px;
  padding: 10px 0px;
  padding-left: 33px;
}
.sidemenu .accordion-body li a:hover {
  text-decoration: none;
  /* color: #4682b4; */
  background: #4682b4;
  color: #fff;
  border-radius: 4px;
}
.static-section-content li a:hover {
  text-decoration: none;
  /* color: #4682b4; */
  /* background: #4682b4; */
  /* color: #fff; */
  /* border-radius: 4px; */
}
.static-section-content li a {
  padding: 18px 10px;
}
.static-section-content .home a img {
  max-width: 20px;
  display: inline-block;
  margin-top: -10px;
}
.static-section-content a img,
.static-section-content a svg {
  margin-right: 5px;
}
.sidemenu .down-angle-icon {
  display: none;
  float: right;
}
.sidemenu .right-angle-icon {
  float: right;
}
.sidemenu .accordion-button[aria-expanded="true"] .right-angle-icon {
  display: none;
}
.sidemenu .accordion-button[aria-expanded="true"] .down-angle-icon {
  display: inline-block;
}
.sidemenu h2 .accordion-button:focus {
  outline: none;
}
.sidemenu h2 .accordion-button img {
  max-width: 18px;
  margin-right: 5px;
}
.sidemenu h2 .accordion-button svg {
  margin-right: 5px;
}
.sidemenu .accordion-item .accordion-body ul li {
}
/* .sidemenu .accordion-button {
    background: #e2e2e2;
    color: #000;
  } */
/* Header Css Start 
  ============================ */
.header {
  height: 50px;
  text-align: right;
  background: steelblue;
}
.header a {
  line-height: 50px;
  color: #cccccc;
  font-weight: 700;
  border-left: 1px solid #fff;
  padding: 15px 15px 15px 30px;
}

.dashboardNumbers {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
}
.dashboardNumbers span {
  font-size: 50px;
  font-weight: 700;
  display: block;
  line-height: 55px;
  color: SteelBlue;
}
.blackText {
  color: #000000 !important;
}
.greenText {
  color: green !important;
}
.redText {
  color: #e60000 !important;
}
.grayText {
  color: #4d4d4d !important;
}
.orangeText {
  color: orange !important;
}
.lightGrayText {
  color: #777777 !important;
}
td,
th {
  width: auto !important;
}
.grayBg {
  background: #4d4d4d;
}
.lightgrayBg {
  background: #eee;
}
.steelBlueBg {
  background: steelBlue !important;
}
/* th {
  background: #777 !important;
  color: #242424;
} */
/* td a {
  font-weight: 800;
  font-size: 16px;
  color: forestgreen;
} */
.text-centre {
  text-align: center;
}
.brandList {
  width: 15%;
  float: left;
  border: 1px solid #eee;
  background: #f9f9f9;
  padding: 15px;
  margin: 10px;
  text-align: center;
}
.brandList img {
  width: 50%;
  padding: 15px;
}
.brandList span {
  display: block;
  padding: 5px;
}
.brandList a {
  padding: 5px 30px;
}
.btn-primary {
  background: steelblue;
  line-height: 35px;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
}
h2.headLine {
  font-size: 28px;
  display: block;
  width: 100%;
  border-bottom: 1px solid #ccc;
  line-height: 50px;
  padding: 0 15px;
  font-weight: 400;
}
h2.headLine span a {
  font-size: 18px;
  font-weight: 500;
  color: #4d4d4d;
  line-height: 50px;
  border-left: 1px solid #ccc;
  padding: 0px 15px;
  float: right;
}
.formPanel {
  padding: 2em;
  background: #f9f9f9;
  margin: 1em;
  border: 1px solid #e5e5e5;
}
.actionTaken td,
.actionTaken th,
.actionTaken table {
  border: 0px;
  border-collapse: collapse;
}
.tableBorder td {
  border: 1px solid #ccc;
}
.tableBorder tr th {
  background: steelblue !important;
  color: #fff;
  padding: 5px;
}

.sideMenuLogo img {
  width: 100%;
}

#resp-table {
  width: 100%;
  display: table;
}
#resp-table-body {
  display: table-row-group;
}
.resp-table-row {
  display: table-row;
}
.table-body-cell {
  display: table-cell;
  border: 1px solid #dddddd;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
/* .Dashboard h2 {
    width: 100%;
    margin-top: 10px;
  }
  .Dashboard h2 .accordion-button {
    font-size: 20px;
    background: steelBlue !important;
    color: #eee;
    background: #f9f9f9;
    border: none;
    padding: 5px 10px;
    width: 100%;
    text-align: left;
    font-weight: 400;
    border-bottom: 1px solid #ccc;
  } */
#div_dynamicdata {
  overflow: scroll;
}
.JobCardAccordion h2 .accordion-button {
  font-size: 20px;
  background: steelBlue !important;
  color: #eee;
  background: #f9f9f9;
  border: none;
  padding: 5px 10px;
  width: 100%;
  text-align: left;
  font-weight: 400;
}

/* added by vinod */
.login-container-sec {
  margin: auto;
  margin-top: 10%;
  border-radius: 7px;
  padding-top: 20px;
}
.login-container-sec img {
  height: 100px;
  width: 200px;
  object-fit: cover;
}

.login-container-sec label {
  padding: 0px;
}

.header-container {
  background-color: #4682b4;

  height: 60px;
  padding: 10px 20px;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}
.header-container img {
  max-width: 140px;
}
.header-right {
  margin: 6px 0px;
}
.header-container .header-right button {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 2px 16px;
  cursor: pointer;
  background-color: #4682b4 !important;
  color: #e2e2e2 !important;
}
.header-container .header-right button .logout-button {
  background-color: #4682b4 !important;
  color: #e2e2e2 !important;
  text-decoration: none !important;
}
.header-container .header-right button a:focus {
  text-decoration: none !important;
}
.header-container .header-right button:focus {
  outline: none;
}
.header-content-container {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.section-container-header {
  display: flex;
  justify-content: flex-start;
  height: 50px;
  background: #e2e2e2;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
}
.section-container-header h3 {
  font-size: 24px;
  color: #4682b4;
}
.section-container-header a,
.section-container-header a:hover {
  margin-left: 20px;
  display: block;
  margin-top: 2px;
  text-decoration: underline;
  color: #007bff;
}
.section-container {
  margin: 20px 0px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin-bottom: 50px;
  display: inline-block;
  width: 100%;
}
.section-container-body {
  margin-top: 15px;
}
.section-container-body thead {
  /* white-space: nowrap; */
}
.section-container-body thead th {
  background: #fff !important;
  color: #000;
  font-weight: 500;
  vertical-align: top;
}

.section-container-body tbody td,
.section-container-body tbody td a {
  font-size: 25px;
}
.user-dashboard-table tbody td,
.user-dashboard-table tbody td a {
  font-size: 20px;
}
.section-container-body .bm-item {
  font-weight: 700;
  /* color: SteelBlue; */
}
.user-dashboard-table td {
  text-transform: lowercase;
}
.section-container-body .user-dashboard-name {
  font-size: 18px;
  text-transform: capitalize;
  width: 200px !important;
}

.user-dashboard-name-th {
  width: 200px !important;
}

.user-dashboard-table tbody {
  display: block;
  height: 400px;
  overflow: auto;
}
.user-dashboard-table thead,
.user-dashboard-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.user-dashboard-table thead {
  width: calc(100% - 1em);
}
.user-dashboard-table table {
  width: 400px;
}

.user-dashboard-table .thead-inverse {
  white-space: pre-line;
}

.user-dashboard-table .table td,
.user-dashboard-table .table th {
  padding: 0.4rem;
}

.focusnext-boby-section {
  /* margin-top: 40px; */
}
.focusnext-boby-content {
  width: 100%;
  margin: auto;
  padding-top: 30px;
  margin-bottom: 200px;
}
.ProductDetails1 label {
  padding-bottom: 0;
  margin-bottom: 0px;
}
.radio-buttons label {
  width: 100%;
}
.radio-buttons .mt-15 {
  margin-top: 5px !important;
}

.items-recieved-section {
  border-top: 1px solid #ced4da;
  padding: 10px 0 20px 0;
}
.create-job-action select {
  text-transform: capitalize;
}
.create-job-action {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.assigned-to-sec {
  width: 250px;
}
.create-job-action .submit-button-action {
  width: 150px;
  margin-top: 20px !important;
}
.items-recieved-body-content > div {
  max-width: fit-content;
  margin: 0 !important;
  display: inline-block;
  padding: 6px 20px;
  margin: 4px !important;
  /* background: #e2e2e2; */
  border-radius: 8px;
  /* padding: 10px; */
  color: #000;
  padding-left: 37px;
  flex: auto;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #ced4da;
}
.items-recieved-body-content > div label {
  cursor: pointer;
}
.section-container-header1 h3 {
  font-size: 24px;
  margin: 0;
}
.remark-text-field input {
}
.focusnext-boby-content label .manditary {
  color: red;
}

.warrenty-type-section .radio-buttons {
  margin: 0;
}

.warrenty-type-section .radio-button-values {
  margin-left: 8px;
  display: flex;
}
.warrenty-type-section .radio-button-values-incoming {
  margin-left: 20px;
}
.left-side-content-sec {
  border-right: 1px solid #ced4da;
}

.dis-none {
  display: none;
}
.dis-none-imp {
  display: none !important;
}
.job-summary-actions {
  text-align: center;
  /* display: block; */
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}
.job-summary-actions input {
  border: none;
  width: 153px;
  cursor: pointer;
}
.job-summary-actions input:hover {
  background: steelblue;
}
.job-summary-actions-filter {
  margin-right: 40px;
}
.job-card-section-container-body thead th {
  background: #fff !important;
  color: #000;
  font-weight: 500;
  vertical-align: top;
}
.job-card-filter-summary-data {
  margin-bottom: 200px;
}
.job-card-section-container-body {
  width: 96%;
  margin: auto;
}
.job-item-summary-actions {
  min-width: 120px !important;
  display: flex;
}
.job-item-summary-actions a {
  color: #000;
  margin-right: 20px;
}
.job-item-summary-actions a svg {
  width: 30px;
  height: 30px;
}
.section-container-header1 {
  display: flex;
  background: #d8e6f3;
  padding: 10px;
  border-radius: 7px;
}
.search-icon-sec {
  /* margin-left: 50px; */
}
.search-icon-sec input {
  border-radius: 6px;
  border: 1px solid #ced4da;
  padding-left: 5px;
  height: 36px;
  width: 250px;
  margin-right: 50px;
}
.remark-textarea {
  height: 50px;
}

.items-recieved-section .items-recieved-body-content {
  display: contents;
}

@media (min-width: 1700px) {
  .body-layout-container-sec {
    width: 1600px;
    margin: auto;
  }
}
